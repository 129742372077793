import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Button from 'components/Button'

import Container from 'components/Container'
import image from 'images/shop-management-video.png'
// import { useSiteMetadata } from "../hooks/useSiteMetadata"
import Footer from "components/Footer"
import Header from "components/Header"

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Home() {

  const { t } = useTranslation()

  // const { title } = useSiteMetadata()

  return (
    <>
    <Header/>
    <main>
      <Title 
        title={ t('Features') } 
        subtitle={ t('Schedule appointments, manage your shop and communicate with your customers') }
        dark 
      />
      <Constrain>
        <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/shop-management-video.mp4' />
      </Constrain>

      <Container>

        <h1>{ t(`Shop management`) }</h1>

        <p>
          { t(`Instantly know the status of every vehicle in your shop.`) }
        </p>

        <Button to='/features/shop-management/'>{ t(`Learn more`) }</Button>

        <h1>{ t(`Customer notifications`) }</h1>

        <p>
          { t(`Increase transparency with automatic customer notifications as their vehicle progresses.`) }
        </p>

        <Button to='/features/customer-notifications/'>{ t(`Learn more`) }</Button>

        <h1>{ t(`Team management`) }</h1>

        <p>
          { t(`Manage access for your Service Advisors and Technicians from anywhere. `) }
        </p>

        <Button to='/features/team-management/'>{ t(`Learn more`) }</Button>

        <h1>{ t(`Device management`) }</h1>

        <p>
          { t(`Use all the devices you want and share them between team members. `) }
        </p>

        <Button to='/features/device-management/'>{ t(`Learn more`) }</Button>

      </Container>
  </main>
  <Footer/>
  </>
  )
}
